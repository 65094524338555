// import JsonP from 'jsonp'
import axios from 'axios'
import { Modal } from 'antd'
// import Utils from './../utils/utils'
export default class Axios {

    // static requestList(_this,url,params,isMock){
    //     var data = {
    //         params: params,
    //         isMock
    //     }
    //     this.ajax({
    //         url,
    //         data
    //     }).then((data)=>{
    //         if (data && data.result){
    //             let list = data.result.item_list.map((item, index) => {
    //                 item.key = index;
    //                 return item;
    //             });
    //             _this.setState({
    //                 list,
    //                 pagination: Utils.pagination(data, (current) => {
    //                     _this.params.page = current;
    //                     _this.requestList();
    //                 })
    //             })
    //         }
    //     });
    // }
    // static jsonp(options) {
    //     return new Promise((resolve, reject) => {
    //         JsonP(options.url, {
    //             param: 'callback'
    //         }, function (err, response) {
    //             if (response.status == 'success') {
    //                 resolve(response);
    //             } else {
    //                 reject(response.messsage);
    //             }
    //         })
    //     })
    // }

    static ajax(options){
        let baseApi  = 'https://msapi.meicun.xin/DefaultController/post/';
        let data=(options.data && options.data.params) || '';
        data['ts'] = new Date().getTime();
        data['api_request'] = data.api_request || '3';
        data['sign'] = '8a851a5f5b4def2e244a4adfb02cb7d5';
        data['token']='564ec27a8e97cf934146e7b80550dd5d';
        return new Promise((resolve,reject)=>{
            axios({
                url:options.url,
                method:'post',
                baseURL:baseApi,
                timeout:5000,
                params:data
            }).then((response)=>{
                if (response.status == '200'){
                    let res = response.data;
                    if (res.status){
                        resolve(res);
                    }else{
                        Modal.info({
                            title:"提示",
                            content:res.msg
                        })
                    }
                }else{
                    reject(response.data);
                }
            })
        });
    }
}