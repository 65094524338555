/* eslint-disable */
import React from 'react';
import {Button,Carousel,Input,Form,Modal,Anchor} from "antd";
import { Swiper } from 'swiper';
import axios from './../../axios/index';
import './index.less';
// import 'swiper/swiper.less';
const { TextArea } = Input;
const { Link } = Anchor;
// let Swiper = window.Swiper,
let thumbsSwiper=null,gallerySwiper=null;

export default class Index extends React.Component{
    formRef = React.createRef();
    constructor(props){
        super(props);
        this.state={
            indexs:0,
            styleList:[0,0,0,0,0,0],
            formdata:{},
            targetOffset:0
        };
    }
    // 默认请求我们的接口数据
    requestList = (data)=>{
        let _this = this;
        let _data={
            supply_method:'美村官网海外站',
            contacts:data.name,
            contacts_mobile:data.phone,
            varieties:data.email
        };
        if(data.message){
            _data.content=data.message;
        }
        
        axios.ajax({
            url: 'meicun-integral-admin-service/suggest/request_save',
            data:{
                params:_data
            }
        }).then((res)=>{
            Modal.success({
                title:"Tips",
                content:'Submitted successfully',
                onOk(){
                    _this.formRef.current.resetFields();
                  },
            })
        })
    }
    handleWheel=(event)=>{
        //判断鼠标滚轮的上下滑动
        let deta = event.deltaY;
        let that=this;
        let _data=[];
        if(deta > 0){
            that.state.styleList.map(item => {
                let num=Math.random()*20;
               let sum=parseInt(num+item);
               if(sum<0){
                sum=0;
               }
               _data.push(sum);
                return sum;
            });
            that.setState({
                styleList:_data
            });
        }
        if(deta < 0){
            that.state.styleList.map(item => {
                let num=Math.random()*12;
               let sum=parseInt(item-num);
               if(sum<0){
                sum=0;
               }
               _data.push(sum);
                return sum;
            });
            this.setState({
                styleList:_data
            });
        }
    }
    scrollToBottom() {
        if (this.messagesEnd) {
            window.scrollTo(0,5700);
        }
    }
    appendMeta = () =>{
        try {
         if(document.getElementById("bdmark") != null){
           return;
         }
         var urlhash = window.location.hash;
         if (!urlhash.match("fromapp")){
            if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad)/i))) {
              location.replace('https://en-m.meicun.xin/');
            }
         }
       } catch(err){}
    }
    componentWillMount(){
        this.appendMeta()
    }
    componentWillReceiveProps(){  //刷新页面时执行
        this.appendMeta()
    }
    componentDidMount(){
        if(this.thumbsSwiper){
            this.thumbsSwiper.slideTo(0, 0)
            this.thumbsSwiper.destroy()
            this.thumbsSwiper = null;
           }
           this.thumbsSwiper = new Swiper('#thumbs', {
              spaceBetween: 10,
              slidesPerView: 5,
              watchSlidesVisibility: true, //防止不可点击
          })
          this.gallerySwiper = new Swiper('#gallery', {
              effect : 'coverflow',
              spaceBetween: 10, //缩略图间距
              slidesPerView: 5,
              centeredSlides: true,
              initialSlide :2,
              loop: true,
              autoplay: false,
              thumbs: {
                  swiper: this.thumbsSwiper,
              }
          })
    }
    componentWillUnmount() {
        if (this.thumbsSwiper) { // 销毁swiper
         this.thumbsSwiper.destroy()
        }
    }
    render(){   
        const onFinish = values => {
            if(!values.name){
                return;
            }
            if(!values.email){
                return;
            }
            if(!values.phone){
                return;
            }
            // console.log('Received values of form: ', values);
            this.requestList(values);
          };
          
        return <div className="body" onWheel={this.handleWheel} ref={(el) => { this.messagesEnd = el; }}>
            <div className="head">
                <div className="div">
                    <img src="./assets/logo.png" alt=""/>
                    <div className="nav-list">
                        <Anchor affix={false}  targetOffset={'100'}>
                        <Link href="#About" title="About us" />
                        <Link href="#Our" title="Our products" />
                        <Link href="#Contact" title="Contact us" />
                        <Link href="http://meicun.xin/" className="link-text" title="Chinese" />
                        </Anchor>
                        
                    </div>
                </div>
            </div>
            <div className="banner">
                <Carousel autoplay={false}>
                    <div className="contentStyle">
                    <img src="./assets/banner.jpg" alt=""/>
                    </div>
                    <div className="contentStyle">
                    <img src="./assets/banner1.jpg" alt=""/>
                    </div>
                    <div className="contentStyle">
                    <img src="./assets/banner2.jpg" alt=""/>
                    </div>
                </Carousel>
                <div className="banner-text" id="About">
                    Our Mission
                    <p>Beautiful village</p>
                    {/* <p>in every village</p>  */}
                </div>
            </div>
            <div className="block">
                <img src="./assets/circular3.png" className="circular1" style={{transform: 'translate3d(0px, '+this.state.styleList[0]+'px, 0px)'}} alt=""/>
                <img src="./assets/circular2.png" className="circular2" style={{transform: 'translate3d(0px, '+this.state.styleList[1]+'px, 0px)'}} alt=""/>
                <img src="./assets/circular1.png" className="circular3" style={{transform: 'translate3d(0px, '+this.state.styleList[2]+'px, 0px)'}} alt=""/>
                <img src="./assets/circular2.png" className="circular4" style={{transform: 'translate3d(0px, '+this.state.styleList[3]+'px, 0px)'}} alt=""/>
                <img src="./assets/circular3.png" className="circular5" style={{transform: 'translate3d(0px, '+this.state.styleList[4]+'px, 0px)'}} alt=""/>
                <img src="./assets/circular1.png" className="circular6" style={{transform: 'translate3d(0px, '+this.state.styleList[5]+'px, 0px)'}} alt=""/>
            <div className="block h1568">
                <div className="cont1">
                    <div className="div1">
                        <p>Culture</p>
                        <p>Our value is simplicity and openness.</p>
                        <p>Our vision is to be a trusted digital agricultural production service platform.</p>
                        <p>Our mission is continuously innovate to improve the overall efficiency of agriculture.</p>
                    </div>
                    <img src="./assets/photobank.png" className="photobank" alt=""/>
                </div>
                <div className="cont2">
                    <div className="div1">
                        <p>History</p>
                    </div>
                    <div className="div2">
                       <div className="cd-horizontal-timeline">
                            <div className="events-content">
                                <ol>
                                    <li className="selected" data-date="14/02/2020">
                                       <div className="ec-img"><img src="./assets/2015.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                        <p>In 2015</p>
                                        <p> www.jutubao.com officially launched</p>
                                       </div>
                                    </li>
                                    <li data-date="01/04/2020">
                                       <div className="ec-img"><img src="./assets/20152.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                        <p>In 2015</p>
                                        <p>It won the first round of millions of yuan investment from Zhonglu Capital</p>
                                       </div>
                                    </li>

                                    <li data-date="16/06/2020">
                                       <div className="ec-img"><img src="./assets/2017.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                       <p>In 2015</p>
                                        <p>Reached a strategic cooperation with Jingdong</p>
                                       </div>
                                    </li>
                                    <li data-date="01/08/2020">
                                       <div className="ec-img"><img src="./assets/20153.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                        <p>In 2015</p>
                                        <p>Entered Tencent's Makerspace and entered Tencent's "Double Hundred Plan"</p>
                                       </div>
                                    </li>

                                    <li data-date="15/09/2020">
                                       <div className="ec-img"><img src="./assets/2016.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                        <p>In 2016</p>
                                        <p>It won the second round of tens of millions of investment led by Xiangfeng Investment, a subsidiary of Temasek, Singapore</p>
                                       </div>
                                    </li>

                                    <li data-date="31/10/2020">
                                       <div className="ec-img"><img src="./assets/2017.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                        <p>In 2017</p>
                                        <p>Received the third round of tens of millions of investment led by JD Digital.</p>
                                       </div>
                                    </li>

                                    <li data-date="15/11/2020">
                                       <div className="ec-img"><img src="./assets/2018.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                        <p>In 2018</p>
                                        <p>The fourth round of investment of 100 million yuan led by China-Israel Agricultural Industry Fund and Puchao Capital with a state-owned background</p>
                                       </div>
                                    </li>

                                    <li data-date="31/12/2020">
                                       <div className="ec-img"><img src="./assets/2020.png" className="logof" alt=""/></div>
                                       <div className="ec-text">
                                        <p>In 2020</p>
                                        <p>It won the fifth round of 100 million yuan investment led by the China-Israel Agricultural Industry Fund.</p>
                                       </div>
                                    </li>
                                </ol>
                            </div>
                            <div className="timeline">
                                <div className="events-wrapper">
                                    <div className="events">
                                        <ol>
                                            <li><a href="#0" data-date="14/02/2020" className="selected">In 2015</a></li>
                                            <li><a href="#0" data-date="01/04/2020">In 2015</a></li>
                                            <li><a href="#0" data-date="16/06/2020">In 2015</a></li>
                                            <li><a href="#0" data-date="01/08/2020">In 2015</a></li>
                                            <li><a href="#0" data-date="15/09/2020">In 2016</a></li>
                                            <li><a href="#0" data-date="31/10/2020">In 2017</a></li>
                                            <li><a href="#0" data-date="15/11/2020">In 2018</a></li>
                                            <li><a href="#0" data-date="31/12/2020">In 2020</a></li>
                                        </ol>
                                        <span className="filling-line" aria-hidden="true"></span>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block h620">
                <div className="cont3">
                  <div className="video-dec">
                      <p>We are the leading digital service platform for agricultural products. We connected 2 million farmers/cooperatives in China, covering 24 provinces/regions and involving more than 40 kinds of agricultural products.</p>
                  </div>
                  <video src={require('./meicun.mp4').default} controls></video>
                </div>
            </div>
            <div className="block h2166">
                <div className="cont4">
                    <div className="div1">
                        <p>Business model</p>
                    </div>
                    <img src="./assets/photobank2.png" className="photobank2" alt=""/>
                    <div className="c4text"> 
                        <p>
                            <span>Core initiatives</span><span>Form a single-category cluster</span><span>Sorting, roughing, pre-cooling</span><span>The core link of the agricultural supply chain system</span>
                        </p>
                        <p>
                            <span>Business goals</span><span>Enhance the efficiency of origin</span><span>Improve transfer efficiency</span><span>Reduce logistics costs</span>
                        </p>
                    </div>
                    <div className="c4text1">
                        <p>Change from a simple warehouse space provider to a multi-directional solution provider, </p>
                        <p>according to the needs of different customers Full chain customized services such as sorting,  </p>
                        <p>rough processing, warehousing, logistics, etc. </p>
                    </div>
                </div>
                <div className="cont5">
                    <div className="div1" id="Our">
                        <p>Our products</p>
                    </div>
                    <div className="silde">
                        <div className="swiper-container" id="gallery1">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img src="./assets/12.jpg" alt="Apple"/>
                                    <p>Apple</p>
                                    <div className="swiper-hidden">
                                        <p>Colour：Red</p>
                                        <p>Style：fresh</p>
                                        <p>Variety：Fuji</p>
                                        <p>Shape：round</p>
                                        <p>Taste：sweet</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/13.jpg" alt="Orange"/>
                                    <p>Orange</p>
                                    <div className="swiper-hidden">
                                        <p>Colour ：orange</p>
                                        <p>Shape：round</p>
                                        <p>Taste ：sweet</p>
                                        <p>Feature：season fruit</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/1.jpg" alt="Carrot"/>
                                    <p>Carrot</p>
                                    <div className="swiper-hidden">
                                        <p>Colour ：red</p>
                                        <p>Taste ：sweet</p>
                                        <p>Shape：long</p>
                                        <p>Type ：Walnut</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/7.jpg" alt="Onion"/>
                                    <p>Onion</p>
                                    <div className="swiper-hidden">
                                        <p>Colour：red</p>
                                        <p>Shape：round</p>
                                        <p>Taste ：sweet</p>
                                        <p>Packing：bulk as request</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/4.jpg" alt="Garlic"/>
                                    <p>Garlic</p>
                                    <div className="swiper-hidden">
                                        <p>Colour：white</p>
                                        <p>Shape：round</p>
                                        <p>Storage ：in a dry place</p>
                                        <p>Packing：bulk as request</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/10.jpg" alt="Ginger"/>
                                    <p>Ginger</p>
                                    <div className="swiper-hidden">
                                        <p>Colour：yellow</p>
                                        <p>Style：fresh</p>
                                        <p>Taste：spicy</p>
                                        <p>Packing：bulk as request</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/6.jpg" alt="Honey"/>
                                    <p>Honey</p>
                                    <div className="swiper-hidden">
                                        <p>Taste ：sweet</p>
                                        <p>Packing：bottle</p>
                                        <p>Shelf life：2 years</p>
                                        <p>Feature：healthy</p>
                                        <p>Grade ：high quality</p>
                                        <p>Feature：healthy</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/8.jpg" alt="Pepper"/>
                                    <p>Pepper</p>
                                    <div className="swiper-hidden">
                                        <p>Type：spices</p>
                                        <p>Shape：round</p>
                                        <p>Style：dried</p>
                                        <p>Packing：bulk as request</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：chongqing，China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/11.jpg" alt="Red dates"/>
                                    <p>Red dates</p>
                                    <div className="swiper-hidden">
                                        <p>Colour：red</p>
                                        <p>Taste：sweet</p>
                                        <p>Shape：round</p>
                                        <p>Packing：bulk as request</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：xinjiang，China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/2.jpg" alt="Chili"/>
                                    <p>Chili</p>
                                    <div className="swiper-hidden">
                                        <p>Colour ：red</p>
                                        <p>Taste ：spicy</p>
                                        <p>Shape：long round</p>
                                        <p>Type ：spices</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/14.jpg" alt="Pear"/>
                                    <p>Pear</p>
                                    <div className="swiper-hidden">
                                        <p>Colour：yellow</p>
                                        <p>Taste：sweet</p>
                                        <p>Style：fresh</p>
                                        <p>Packing：bulk as request</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <img src="./assets/15.jpg" alt="Nut"/>
                                    <p>Nut</p>
                                    <div className="swiper-hidden">
                                        <p>Style ：dry</p>
                                        <p>Type ：Walnut</p>
                                        <p>Shelf life：12 months</p>
                                        <p>Packing：bulk as request</p>
                                        <p>Grade ：high quality</p>
                                        <p>Origin ：China</p>
                                        <div className="zxbtn" onClick={this.scrollToBottom.bind(this)}>Inquire</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cont6">
                    <div className="div1" id="Contact">
                        <p>Contact details</p>
                    </div>
                    <div className="contact">
                        <div className="c6text">
                            <p>If you have any questions about what we offer for consumers or for business，you can always email us or call us via the below details. We’ll reply within 24 hours.</p>
                            <div className="clist">
                                <div className="cl-li">
                                    <img src="./assets/icon1.png"></img>
                                    <div>
                                        <p>Visit our office</p>
                                        <p>Chongqing Meicun Zhongmi Agricultural Technology Co., Ltd. Building 6, No. 106, West Section Of Jinkai Avenue, Yubei District, Chongqing,China.</p>
                                    </div>
                                </div>
                                <div className="cl-li">
                                    <img src="./assets/icon2.png"></img>
                                    <div>
                                        <p>Telephone</p>
                                        <p>+86 023-81368366</p>
                                    </div>
                                </div>
                                <div className="cl-li">
                                    <img src="./assets/icon2.png"></img>
                                    <div>
                                        <p>Call us on</p>
                                        <p>Mobile  +86 19122340602</p>
                                    </div>
                                </div>
                                <div className="cl-li">
                                    <img src="./assets/icon3.png"></img>
                                    <div>
                                        <p>Mail us at</p>
                                        <p>wangluxuan@meicun.xin</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="c7text3">
                            <p>Leave your message</p>
                            <p className="minip">We are happy to hear you</p>
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                ref={this.formRef}
                                onFinish={onFinish}
                                >
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your Name!' }]}
                                >
                                    <Input placeholder="Your name" />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your Email!' }]}
                                >
                                    <Input placeholder="Email address" />
                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                                >
                                    <Input placeholder="Phone" />
                                </Form.Item>
                                <Form.Item
                                    name="message"
                                >
                                <TextArea
                                        placeholder="Your message..."
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">SEND MESSAGE</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="block h448">
               <div className="cont7">
                   <div className="c7text1">
                      <img src="./assets/logof1.png" alt=""/>
                      <p>Chongqing Meicun Technology Co., Ltd. (abbreviated as: Meicun) was established in April 2015, with the goal of enhancing the value of rural land and promoting the development of agricultural industry, providing users with the entire agricultural industry chain such as agricultural products trading, land transfer, land trusteeship, and land finance. service.<br/>
                      For Meicun family, there are different kinds of branches with different function. <en>Chongqing Meicun Zhongmi Agricultural Technology Co., Ltd. and Chongqing Meicun Yunjin Import and Export Trading Co., Ltd</en> will be responsible for the international business. As the leading company, we are glad to serve for you at any time.</p>
                                    
                   </div>
                   {/* <div className="c7text2">
                       <p>National Free Hotline:</p>
                       <p>400-878-4566</p>
                       <p>（9:00~21:00）</p>
                   </div> */}
                   <div className="c7text3">
                       <p>Reach us at</p>
                       <div className="reach-li">
                           <p><img src="./assets/icon7.png" alt=""/>Address</p>
                           <p>Building 6, No. 106, West Section Of Jinkai Avenue, Yubei District, Chongqing,China.</p>
                       </div>
                       <div className="reach-li">
                           <p><img src="./assets/icon6.png" alt=""/>Email</p>
                           <p>wangluxuan@meicun.xin</p>
                       </div>
                       <div className="reach-li">
                           <p><img src="./assets/icon5.png" alt=""/>Office NO.</p>
                           <p>+86 023-81368366</p>
                       </div>
                   </div>
               </div> 
               <div className="cont8">
                   <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002501540"><img src="./assets/beian.png"  alt=""/>渝公网安备 50019002501540号</a> | Copyright©2015-2021 重庆美村科技有限公司版权所有 www.meicun.xin. all rights reserved. <a target="_blank" href="https://beian.miit.gov.cn">渝ICP备14007043号-5</a>
               </div> 
            </div>
        </div>
    }
}